// Variables
$color-light1:#bedbbb;
$color-light2:#8db596;
$color-dark2:#92817a;
$color-dark1:#707070;
$color-primary: #b23333;
$color-secondary: #c5c5c5;
$color-white: #f2f0f1;
$color-success: #3c7d3c;
$color-warning: #e69b05;
$color-info: #3c607d;
$color-danger: #e03838;
$xs-breakpoint: 575px;
$sm-breakpoint: 767px;
$md-breakpoint: 991px;
$lg-breakpoint: 1199px;