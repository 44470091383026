@import "variables";
@import "mixins";
@import "reset";

.App {
    background-color: $color-light1;
    color: white;
    text-align: center;
    //min-height: 100vh;
  
    .App-header {
      .App-logo {
        width: 16rem;
        cursor: pointer;
      }
    }
}

.App-container {
    //padding: 0 5rem;
    max-width: 1300px;
    margin:0 auto;
    padding-top:4rem;

    @media screen and (max-width: 1439px) {
        max-width: 90%;
    }

    // @media screen and (min-width:$sm-breakpoint) and (max-width: $md-breakpoint) {
    //     max-width: 642px;
    // }

    // @media screen and (max-width: $sm-breakpoint) {
    //     max-width: 500px;
    //     background-color: tomato;
    // }

    // @media screen and (max-width: $xs-breakpoint) {
    //     max-width: 400px;
    //     background-color: #fff;
    // }
    
}

//---------------------HOME----------------------------

// @media (max-width: $lg-breakpoint) {
//     body {
//         padding:2rem;
//     }
// }


//-----------HEADER--------------

.App-header {
    background-color: $color-dark1;
    position:fixed;
    top:0;
    /* par défaut l'élément ne prend que la place dont-il a besoin 
    Pour que ça fasse toute la largeur, il faut lui dire left:0 et droite:0 pour qu'il s'élargisse sur toute la largeur*/
    right:0;
    left:0;
    z-index:1;

    .App-container {
        background-color: $color-dark1;
        font-family: 'Playfair Display', sans-serif;
        font-weight: bold;
        display:flex;
        justify-content: space-between;
        align-items:center;
        //padding: 0 5rem;
        height: 6.75rem;
        padding-top:0rem;
    }
    

    a {
        font-size:3.6rem;
        letter-spacing: 0.5rem;
        span {
            color:white;
        }
    }

    .ant-menu {
        background: none;
        color:white;
        .ant-menu-item {
            a{
                color:white;
                font-size:1.6rem;
            }
        }
    }

    .ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title span {
        color:white;
        font-size:1.6rem;
        letter-spacing: 0.5rem;
    }

}

//----------BANNIERE-----------

.banniere {
    position:relative;
    height: 65.25rem;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 100%), 100% url("../img/background.jpg");
    background-position: center;
    background-size: cover;
    h1, h2 {
        text-align:left;
        font-family:'Playfair Display', sans-serif;
        color:$color-dark1;
        font-size:2.4rem;

    }
    h1 {
        font-weight: bold;
        font-size:3.6rem;
    }
    span {
        position:absolute;
        bottom:2rem;
        font-size:3.6rem;
        color:#333;
        animation: scrollDownUp 2s ease-out infinite;
    }
}

@keyframes scrollDownUp {
    0% {
        transform: translate(0, 10px);
    }
    50% {
        transform: translate(0, 20px);
    }
    100% {
        transform: translate(0, 10px);
    }
}

//------------ABOUT-------------

.about {

    padding-bottom:5rem;

    .ant-card-body {
        display:none;
    } 

    .ant-card {
        background: none;
        border: 0;
    }

    .ant-col {
        padding-bottom:2rem;
    }
    
    h1 {
        text-align:left;
        font-family:'Playfair Display', sans-serif;
        color:$color-dark1;
        font-weight: bold;
        font-size:3.6rem;
    }

    p {
        margin-top:3rem;
        text-align:left;
        font-family: 'Roboto', sans-serif;
        color:black;
        font-size:1.8rem;
    }

    .moreAboutMe {
        color:#333;
        font-size:1.8rem;
        &:hover {
            color:white;
            cursor: pointer;
        }
    }

    .fullDescriptionText {
        text-align: justify;
    }

    .ant-btn {
        //flex:1;
        display: block;
        background-color: $color-dark2;
        border: 0.2rem solid $color-dark1;
        box-shadow: 2px 2px 2px #333;
        color: white;
        font-size: 1.6rem;
        padding:1.5rem 7rem;
        height: auto;
        margin-top:6rem;
        font-size:1.8rem;
        font-family:'Playfair Display', sans-serif;
        font-weight: bold;

        &:hover{
            background-color: white;
            color: $color-dark2;
          }

        @media screen and (max-width:$md-breakpoint){
              margin:6rem auto 0;
        }
      }
}

.ant-modal-header {
    background-color: $color-dark1;
    border:none;
    
}

.ant-modal-title {
    color:white;
    font-family: 'Playfair Display';
}

.ant-modal-footer {
    background-color: $color-dark1;
    border:none;
}

.ant-btn-primary {
    background-color: #333;
    border-color: #333;
    margin: 0 0.5rem;
    width: 3.5rem;
    height:3.5rem;
    border-radius:50%;
        //animation: DownUp 1.5s ease-out infinite;
        &:hover{
            background-color: white;
            border-color: white;
            span {
                color:#333;
            }
        }
        span {
            color:white;
            text-align: center;
        }
}

@media (max-width: $sm-breakpoint) {
    .about h1, .banniere h1 {
      font-size: 2.4rem;
    }
    .banniere h2 {
        font-size:1.6rem;
    }
}

//--------------FOOTER--------------

.App-footer {
    background-color: $color-dark1;
    color:white;
    .App-container {
        background-color: $color-dark1;
        font-weight: bold;
        display:flex;
        justify-content: space-between;
        align-items:center;
        //padding: 0 5rem;
        height: 6.75rem;
        padding-top:0rem;
        .copyright {
            font-family: 'Playfair Display', sans-serif;
        }
    }
}

.link {
    font-family: 'Roboto', sans-serif;
    display:flex;
    .media-btn {
        margin: 0 0.5rem;
        width: 3.5rem;
        height:3.5rem;
        border-radius:50%;
        background-color: #333;
        display:flex;
        justify-content: center;
        align-items:center;
        animation: DownUp 1.5s ease-out infinite;
        &:hover{
            background-color: white;
            .icones {
                color:#333;
            }
        }
        .icones {
            color:white;
        }
    }
}

@keyframes DownUp {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 5px);
    }
    100% {
        transform: translate(0, 0px);
    }
}

// ------------------- COMPOSANT CONTACT ----------------

.contact {
    @media screen and (min-width:$lg-breakpoint) {
        height: 58.5rem;
    }
    
}

.Form-container {
    padding:0rem 5rem;
}

.App {
    .contact {
        .App-container {
            padding-top:2rem;
        }
        .mail-phone {
            padding-top:5rem;
            align-content: center;
            justify-content: center;
        }
        h1, h2 {
            text-align:center;
            font-family:'Playfair Display', sans-serif;
            color:$color-dark1;
            font-size:2.4rem;
    
        }
        h1 {
            font-weight: bold;
            font-size:3.6rem;
        }
        a, p {
            color:black;
            font-family: 'Roboto', sans-serif;
            &:hover {
                color:white;
            }
        }
    }

    form {
        background-color: $color-light2;
        border: 1px solid $color-dark2;
        box-shadow:  2px 2px 2px $color-dark1;
        width:60%;
        min-width: 35rem;
        margin:auto;
        margin-bottom:2rem;
        padding-top:2rem;
        padding-bottom:2rem;
        input, textarea {
            border: 1px solid $color-dark2;
            box-shadow:  2px 2px 2px $color-dark1;
            background-color: $color-light1;
            margin:1rem;
            padding:0.7rem;
            font-family: 'Roboto', sans-serif;
            color:$color-dark1;
            width:90%;
        }
        

        input[type="submit"]{
            cursor: pointer;
            background-color: $color-dark2;
            border: 0.2rem solid $color-dark1;
            box-shadow: 2px 2px 2px #333;
            color: white;
            font-size: 1.6rem;
            padding:0.5rem 5rem;
            height: auto;
            margin:0;
            font-size:1.8rem;
            font-family:'Playfair Display', sans-serif;
            font-weight: bold;
            width:auto;

            &:hover{
            background-color: white;
            color: $color-dark2;
            }
        }
    }
}

// ------------------COMPOSANT PROJETS-------------------------------------------

.projects {
    @media screen and (min-width:$lg-breakpoint) {
        height: 58.5rem;
    }
    
}

.App {
    .projects {
        .App-container {
            padding-top:2rem;
        }
        h1, h2 {
            text-align:center;
            font-family:'Playfair Display', sans-serif;
            color:$color-dark1;
            font-size:2.4rem;
    
        }
        h1 {
            font-weight: bold;
            font-size:3.6rem;
        }
        .ant-carousel {
            width:80%;
            margin:auto;
            margin-top:2rem;
            margin-bottom:6rem;
            padding-bottom:2rem;
            background: #8db596;
            box-shadow:2px 2px 2px $color-dark1;
        }
    }
}