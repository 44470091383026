* {
    box-sizing: border-box;
}

html {
    // Rédéfinir la taille par défaut d'1 rem à 10px plutôt que 16px
    font-size: 62.5%;
}

body {
    // On rédéfinit une taille par défaut à 16px
    font-size: 1.6rem;
    

    // Appliquer la mixin no-margin
    @include no-margin;
    padding-top: 6.75rem;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}